import React, { useEffect, useState } from 'react';
import {
  List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Button, Box,
  TextField, IconButton, AppBar, Toolbar, useMediaQuery, Drawer, ListItemIcon,
  Container, Fade, Grow
} from '@mui/material';
import { ThemeProvider, createTheme, useTheme, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { list, getUrl } from 'aws-amplify/storage';
import { signOut } from 'aws-amplify/auth';
import {
  PictureAsPdf as PictureAsPdfIcon,
  CloudDownload as CloudDownloadIcon,
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  Person as PersonIcon,
  Message as MessageIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon
} from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#ff0000',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const Publications = () => {
  const [files, setFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    setIsLoading(true);
    try {
      const result = await list({ prefix: 'Publications' });
      const modifiedFiles = result.items.map(file => ({
        ...file,
        name: file.key.replace('Publications/', '').split('(')[0].trim(),
        additionalInfo: file.key.match(/\(.*?\)/)?.[0] || '',
      }));
      modifiedFiles.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
      setFiles(modifiedFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFiles = files.filter(file =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = async (filename) => {
    try {
      const { url } = await getUrl({ key: filename, options: { accessLevel: 'guest' } });
      window.open(url.href);
    } catch (error) {
      console.error('Error getting signed URL:', error);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/admin_aries_app/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleHelp = () => {
    const email = 'syserp@aries.res.in';
    const subject = 'Need help With Aries Connect';
    window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}`);
  };

  const drawerContent = (
    <Box sx={{ width: 250, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h6" component="div">
          Aries Connect
        </Typography>
      </Box>
      <List sx={{ flexGrow: 1 }}>
        {[
          { text: 'Home', icon: <HomeIcon />, action: () => handleNavigation('/admin_aries_app/Home') },
          { text: 'User Info', icon: <PersonIcon />, action: () => handleNavigation('/admin_aries_app/UserInfo') },
          { text: 'Messages', icon: <MessageIcon />, action: () => handleNavigation('/admin_aries_app/Msg') },
          { text: 'Help', icon: <HelpIcon />, action: handleHelp },
        ].map((item, index) => (
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 + index * 200 }} key={item.text}>
            <StyledListItem button onClick={item.action}>
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          </Grow>
        ))}
      </List>
      <Fade in={true} style={{ transitionDelay: '800ms' }}>
        <Box sx={{ p: 2 }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<ExitToAppIcon />}
            onClick={handleLogout}
            sx={{ backgroundColor: '#ff5309', '&:hover': { backgroundColor: '#e64a00' } }}
          >
            Logout
          </Button>
        </Box>
      </Fade>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Publications
            </Typography>
            {!isMobile && (
              <>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Home')}>Home</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/UserInfo')}>User Info</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Msg')}>Messages</Button>
                <Button color="inherit" onClick={handleHelp}>Help</Button>
                <Button color="inherit" onClick={handleLogout}>Logout</Button>
              </>
            )}
          </Toolbar>
        </AppBar>

        <StyledDrawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {drawerContent}
        </StyledDrawer>

        <Container maxWidth="md" sx={{ mt: 4 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search Publications..."
            InputProps={{
              startAdornment: <SearchIcon color="action" />,
            }}
            onChange={handleSearch}
            sx={{ mb: 3 }}
          />

          {isLoading ? (
            <Typography>Loading Publications...</Typography>
          ) : (
            <List>
              {filteredFiles.map((file, index) => (
                <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 500 + index * 100 }} key={index}>
                  <ListItem
                    sx={{
                      mb: 2,
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                      boxShadow: 1,
                      '&:hover': {
                        bgcolor: 'action.hover',
                        transform: 'translateY(-4px)',
                        transition: 'transform 0.3s ease-in-out',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <StyledAvatar>
                        <PictureAsPdfIcon />
                      </StyledAvatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name}
                      secondary={file.additionalInfo}
                    />
                    <IconButton
                      onClick={() => handleDownload(file.key)}
                      color="primary"
                      sx={{
                        '&:hover': {
                          bgcolor: 'primary.light',
                          transform: 'scale(1.1)',
                          transition: 'all 0.3s ease-in-out',
                        },
                      }}
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  </ListItem>
                </Grow>
              ))}
            </List>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Publications;