import React, { useState, useEffect } from 'react';
import {
  AppBar, Toolbar, IconButton, Typography, Grid, Box, useMediaQuery,
  Button, Drawer, List, ListItem, ListItemIcon, ListItemText, Container,
  Grow, Fade, Avatar, useTheme, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { keyframes } from '@mui/material/styles';
import { Form, useNavigate } from 'react-router-dom';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { DataStore } from 'aws-amplify/datastore';
import { USER, Notify } from '../models';
import { getUrl } from 'aws-amplify/storage';
import {
  PremiumAppBar,
  StyledIconButton,
  StyledAvatar,
  LogoWrapper,
  NavButton,
  UserInfoBox,
  DrawerAvatar,
  UserName,
  UserRole
} from './PremiumNavbarStyles';
import UserMenu from './UserMenu';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Person as PersonIcon,
  Message as MessageIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon,
  NotificationsActive as NotificationsActiveIcon,
  // Business as ErpIcon,
  TurnRight,
  // PhotoLibrary as GalleryIcon,
  // Gavel as GovtOrderIcon,
  // Event as HolidayIcon,
  // Policy as PolicyIcon,
  // AccountBalance as NpsIcon,
  // Announcement as NoticeIcon,
  // Description as FormsIcon,
  // Receipt as PaySlipIcon,
  // PhoneInTalk as TelDirectoryIcon,
  // VerifiedUser as LifeCertificateIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import contactIconJpeg from '../Images/download__1_-removebg-preview (1).png';
import OfficeOrderIcon from '../Images/of_order-removebg-preview.png';
import Gallery from '../Images/gallery2-removebg-preview.png';
import Policy from '../Images/policy-removebg-preview.png';
import Notice from '../Images/7803013.png';
import PaySlip from '../Images/payslip_new.png';
import Forms from '../Images/form.png';
import Holiday from '../Images/holiday_new.png';
import Teldirect from '../Images/tel_dire-removebg-preview.png';
import LifeCertificate from '../Images/life-removebg-preview.png';
import GovtOrderIcon from '../Images/download__2_-removebg-preview.png';
import nps from '../Images/download-removebg-preview.png';
import erp from '../Images/erp2-removebg-preview.png';
import Publication from '../Images/publications-icon-removebg-preview.png';

import {
  EnhancedMenuItemCard,
  EnhancedIconWrapper,
  MobileNavButton,
  EnhancedDrawer,
  EnhancedImageSlider
} from './AnimatedStyles';



// Import your images
import sliderImage1 from '../Images/1.jpeg';
import sliderImage2 from '../Images/2.jpeg';
import sliderImage3 from '../Images/3.jpeg';
import sliderImage4 from '../Images/4.jpeg';
import sliderImage5 from '../Images/5.jpeg';
import sliderImage6 from '../Images/6.jpeg';
import sliderImage7 from '../Images/7.jpeg';
import sliderImage8 from '../Images/8.jpeg';
import sliderImage9 from '../Images/9.jpeg';
import sliderImage10 from '../Images/10.jpeg';
import sliderImage11 from '../Images/11.jpeg';
import sliderImage12 from '../Images/12.jpeg';

import ariesLogo from '../Images/aries_logo.png';
const SLIDER_INTERVAL = 5000;
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#2563eb',
//       light: '#60a5fa',
//       dark: '#1e40af',
//     },
//     secondary: {
//       main: '#f97316',
//       light: '#fdba74',
//       dark: '#c2410c',
//     },
//   },
//   typography: {
//     fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
//     h6: {
//       fontFamily: '"Poppins", sans-serif',
//       fontWeight: 600,
//     },
//     button: {
//       fontFamily: '"Inter", sans-serif',
//       fontWeight: 500,
//       textTransform: 'none',
//     },
//   },
// });
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 240,
    background: theme.palette.primary.main, // Solid blue background
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  margin: '4px 0',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateX(5px)',
  },
}));
const LogoutContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: 'auto',
}));


// const UserInfoBox = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   padding: theme.spacing(4, 2),
//   background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
//   position: 'relative',
//   overflow: 'hidden',
//   '&::before': {
//     content: '""',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
//     opacity: 0.5,
//   },
// }));

// const StyledAvatar = styled(Avatar)(({ theme }) => ({
//   width: 80,
//   height: 80,
//   marginBottom: theme.spacing(2),
//   border: `3px solid ${theme.palette.common.white}`,
//   boxShadow: `0 0 0 4px ${theme.palette.primary.main}, 0 0 0 8px rgba(255, 255, 255, 0.3)`,
//   animation: `${pulse} 2s infinite`,
// }));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '80px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(1.5),
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  }
}));

const IconContent = styled(Box)(({ customWidth, customHeight }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  '& .MuiSvgIcon-root': {
    width: customWidth || '80px',
    height: customHeight || '80px',
    color: '#2563eb',
  },
  '& img': {
    width: customWidth || '80px',
    height: customHeight || '80px',
    objectFit: 'contain'
  }
}));


const MenuItemCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: 16,
  padding: theme.spacing(2),
  display: 'flex',
  // flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '150px',
  cursor: 'pointer',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  boxShadow: '0 4px 8px rgba(0,0,0,0.05)',
  border: '1px solid rgba(59, 130, 246, 0.1)',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 16px rgba(59, 130, 246, 0.15)',
    background: 'linear-gradient(145deg, #ffffff, #e8f0fe)',
    '& .menu-item-label': {
      color: theme.palette.primary.main,
    },
  },
  [theme.breakpoints.down('sm')]: {
    height: '130px',
    padding: theme.spacing(1.5),
  }
}));


const ImageSlider = styled(Box)(({ theme }) => ({
  height: 200,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  position: 'relative',
  boxShadow: '0 8px 16px rgba(0,0,0,0.08)',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  [theme.breakpoints.up('sm')]: {
    height: 280,
  },
}));



// const UserName = styled(Typography)(({ theme }) => ({
//   fontWeight: 'bold',
//   fontSize: '1.2rem',
//   textShadow: '0 2px 4px rgba(0,0,0,0.3)',
//   marginBottom: theme.spacing(1),
// }));

// const UserRole = styled(Typography)(({ theme }) => ({
//   fontSize: '0.9rem',
//   opacity: 0.8,
//   textTransform: 'uppercase',
//   letterSpacing: '1px',
// }));


const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [userName, setUserName] = useState('');
  const [userData, setUserData] = useState(null);
  const [hasUnseenNotification, setHasUnseenNotification] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const images = [sliderImage1, sliderImage2, sliderImage3, sliderImage4, sliderImage5, sliderImage6, sliderImage7, sliderImage8, sliderImage9, sliderImage10, sliderImage11, sliderImage12];
  const [openHolidayDialog, setOpenHolidayDialog] = useState(false);
  const [openTelDialog, setOpenTelDialog] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  useEffect(() => {
    fetchUserData();
    checkNotificationStatus();

    const timer = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, SLIDER_INTERVAL);

    return () => clearInterval(timer);
  }, [images.length]);

  const fetchUserData = async () => {
    try {
      const { userId } = await getCurrentUser();
      const users = await DataStore.query(USER);
      const currentUser = users.find(user => user.uid === userId);
      if (currentUser) {
        setUserName(currentUser.name);
        setUserData(currentUser);
        setEmployeeId(currentUser.EmpID);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const handleHolidayClick = () => {
    setOpenHolidayDialog(true);
  };
  const handleCloseHolidayDialog = () => {
    setOpenHolidayDialog(false);
  };
  const handleHolidayView = async () => {
    try {
      const filename = "downloads/Calendar_page.jpg";
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });
      localStorage.setItem('IMG_URL', getUrlResult.url.href);
      navigate("/admin_aries_app/Img");
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
    setOpenHolidayDialog(false);
  };

  const handleHolidayDownload = async () => {
    try {
      const filename = "downloads/Calendar.pdf";
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });
      window.open(getUrlResult.url.href);
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
    setOpenHolidayDialog(false);
  };

  const handleTelClick = () => {
    setOpenTelDialog(true);
  };

  const handleCloseTelDialog = () => {
    setOpenTelDialog(false);
  };

  const handleTelView = async () => {
    try {
      const filename = "downloads/Telephone_Directory.jpg";
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });
      localStorage.setItem('tel_url', getUrlResult.url.href);
      navigate("/admin_aries_app/TelImg");
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
    setOpenTelDialog(false);
  };

  const handleTelDownload = async () => {
    try {
      const filename = "downloads/Telephone_Directory.pdf";
      const getUrlResult = await getUrl({
        key: filename,
        options: {
          accessLevel: "guest",
        },
      });
      window.open(getUrlResult.url.href);
    } catch (error) {
      console.error("Error getting signed URL:", error);
    }
    setOpenTelDialog(false);
  };

  const checkNotificationStatus = async () => {
    try {
      const { userId } = await getCurrentUser();
      const notifications = await DataStore.query(Notify);
      const hasUnseen = notifications.some(
        notification => notification.username === userId && !notification.seen
      );
      setHasUnseenNotification(hasUnseen);
    } catch (error) {
      console.error("Error checking notification status:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/admin_aries_app/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleHelp = () => {
    const email = 'syserp@aries.res.in';
    const subject = 'Need help With Aries Connect';
    window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}`);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserInfo = () => {
    handleClose();
    navigate('/admin_aries_app/UserInfo');
  };

  const handleLogoutFromMenu = () => {
    handleClose();
    handleLogout();
  };

  const menuItems = [
    {
      icon: (
        <Box
          component="img"
          src={contactIconJpeg}
          alt="Contact"
          sx={{
            width: '160px',  // Increased from previous size
            height: '160px', // Increased from previous size
            objectFit: 'contain',
            transform: 'scale(1.6)' // Added scaling for additional size increase
          }}
        />
      ),
      onClick: () => handleNavigation("/admin_aries_app/Contact"),
      customSize: { width: '300px', height: '300px' } // Increased container size
    },
    {
      icon: (
        <Box
          component="img"
          src={erp}
          alt="erp"
        />
      ),
      onClick: () => window.open("http://office.aries.res.in", "_blank"),
      showIf: userData?.active,
      customSize: { width: '85px', height: '97px' }
    },
    {
      icon: (
        <Box
          component="img"
          src={Gallery}
          alt="Gallery"
          sx={{
            width: '52px',
            height: '52px',
            objectFit: 'contain',
            transform: 'scale(1.3)' 
          }}
        />
      ),
      onClick: () => handleNavigation("/admin_aries_app/gallery"),
      customSize: { width: '90px', height: '57px' }
    },
    {
      icon: (
        <Box
          component="img"
          src={GovtOrderIcon}
          alt="Govt Order"
          sx={{
            width: '110px',  // Increased from previous size
            height: '110px', // Increased from previous size
            objectFit: 'contain',
            transform: 'scale(1.4)' // Added scaling for additional size increase
          }}
        />
      ),
      onClick: () => handleNavigation("/admin_aries_app/Order"),
      customSize: { width: '250px', height: '250px' } // Increased container size
    },
    {
      icon: (
        <Box
          component="img"
          src={Policy}
          alt="Policy"
          sx={{
            width: '84px',  // NOW PROPERLY INCREASED
            height: '84px',
            objectFit: 'contain',
            transform: 'scale(1.25)' 
          }}
        />
      ),
      // label: 'Policy',
      onClick: () => handleNavigation("/admin_aries_app/policypage"),
      customSize: { width: '104px', height: '184px' } // Added customSize
    },
    {
      icon: (
        <Box
          component="img"
          src={nps}
          alt="NPS Update"
          sx={{
            width: '52px',
            height: '52px',
            objectFit: 'contain',
            transform: 'scale(1.4)' 
          }}
        />
      ),
      // label: 'NPS Update',
      onClick: () => handleNavigation("/admin_aries_app/NpsPage"),
      customSize: { width: '192px', height: '152px' }
    },
    {
      icon: (
        <Box
          component="img"
          src={Notice}
          alt="Notice"
          sx={{
            width: '52px',
            height: '52px',
            objectFit: 'contain',
            transform: 'scale(1.25)' 
          }}
        />
      ),
      // label: 'Notice',
      onClick: () => handleNavigation("/admin_aries_app/Noticepage"),
      customSize: { width: '62px', height: '192px' }
    },
    {
      icon: (
        <Box
          component="img"
          src={Forms}
          alt="Forms"
          sx={{
            width: '84px',
            height: '84px',
            objectFit: 'contain'
          }}
        />
      ),
      // label: 'Forms',
      onClick: () => handleNavigation("/admin_aries_app/FormsPage"),
      customSize: { width: '72px', height: '152px' }
    },
    {
      icon: (
        <Box
          component="img"
          src={OfficeOrderIcon}
          alt="Office Orders"
          sx={{
            width: '32px',
            height: '32px',
            objectFit: 'contain',
            transform: 'scale(1.3)' 
          }}
        />
      ),
      // label: 'Office Orders',
      onClick: () => handleNavigation("/admin_aries_app/OfficeOrders"),
      customSize: { width: '90px', height: '67px' } 
    },
    {
      icon: (
        <Box
          component="img"
          src={PaySlip}
          alt="Pay Slip"
          sx={{
            width: '52px',
            height: '52px',
            objectFit: 'contain',
            transform: 'scale(1.3)' 
          }}
        />
      ),
      // label: 'Pay Slip',
      onClick: () => handleNavigation("/admin_aries_app/PaySlip"),
      customSize: { width: '99px', height: '70px' } 
    },
    {
      icon: (
        <Box
          component="img"
          src={Holiday}
          alt="Holiday"
          sx={{
            width: '84px',  // NOW PROPERLY INCREASED
            height: '84px',
            objectFit: 'contain',
            transform: 'scale(1.4)' 
          }}
        />
      ),
      // label: 'Holiday',
      onClick: handleHolidayClick,
      customSize: { width: '90px', height: '70px' } // Added customSize
    },
    {
      icon: (
        <Box
          component="img"
          src={Teldirect}
          alt="Tel Directory"
          sx={{
            width: '82px',  // Custom size for Tel Directory
            height: '82px',
            objectFit: 'contain',
            transform: 'scale(1.4)' 
          }}
        />
      ),
      // label: 'Tel Directory',
      onClick: handleTelClick,
      customSize: { width: '82px', height: '73px' }
    },
    {
      icon: (
        <Box
          component="img"
          src={LifeCertificate}
          alt="Life Certificate"
          sx={{
            width: '64px',
            height: '64px',
            objectFit: 'contain',
            transform: 'scale(1.4)' 
          }}
        />
      ),
      // label: 'Life Certificate',
      onClick: () => handleNavigation("/admin_aries_app/LifeCertificate", { state: { detailSend: userData } }),
      showIf: !userData?.active,
      largeIcon: true
    },
    {
      icon: (
        <Box
          component="img"
          src={Publication}
          alt="Publication"
          sx={{
            width: '64px',
            height: '64px',
            objectFit: 'contain',
            transform: 'scale(1.25)' 
          }}
        />
      ),
      onClick: () => handleNavigation("/admin_aries_app/Publications"),
      customSize: { width: '90px', height: '70px' }
    }

  ];
  return (
    <Box sx={{ flexGrow: 1, bgcolor: '#f0f2f5', minHeight: '100vh', background: 'linear-gradient(145deg, #dcdddf, #e8edf2)' }}>
      <PremiumAppBar elevation={0}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <MobileNavButton
              edge="start"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
              <LogoWrapper>
                <img src={ariesLogo} alt="Aries Logo" />
              </LogoWrapper>
              <LogoWrapper>

                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    background: 'linear-gradient(90deg, #ffffff, #e2e8f0)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textShadow: '0 2px 4px rgba(0,0,0,0.1)'
                  }}
                >
                </Typography>
              </LogoWrapper>
            </MobileNavButton>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isMobile && (
              <>
                <NavButton startIcon={<HomeIcon />} onClick={() => handleNavigation('/home')}>
                  Home
                </NavButton>
                <NavButton
                  startIcon={<MessageIcon />}
                  onClick={() => handleNavigation('/admin_aries_app/Msg')}
                >
                  Messages
                </NavButton>
                <NavButton
                  startIcon={<HelpIcon />}
                  onClick={handleHelp}
                >
                  Help
                </NavButton>
              </>
            )}

            {hasUnseenNotification && (
              <StyledIconButton onClick={() => handleNavigation('/admin_aries_app/Msg')}>
                <NotificationsActiveIcon />
              </StyledIconButton>
            )}

            <StyledIconButton
              onClick={handleMenu}
              size="small"
              sx={{ ml: 1 }}
            >
              <StyledAvatar>
                {userName ? userName.charAt(0).toUpperCase() : 'U'}
              </StyledAvatar>
            </StyledIconButton>
          </Box>
        </Toolbar>
      </PremiumAppBar>


      <UserMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onUserInfo={handleUserInfo}
        onLogout={handleLogoutFromMenu}
      />
      <EnhancedDrawer>
        <StyledDrawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <UserInfoBox>
            <DrawerAvatar>
              {userName ? userName.charAt(0).toUpperCase() : 'U'}
            </DrawerAvatar>
            <UserName>
              {userName || 'User Name'}
            </UserName>
            <UserRole>
              Employee ID: {employeeId || 'N/A'}
            </UserRole>
          </UserInfoBox>

          <List sx={{ p: 2, flexGrow: 1 }}>
            {[
              { text: 'Home', icon: <HomeIcon />, path: '/admin_aries_app/Home' },
              { text: 'Profile', icon: <PersonIcon />, path: '/admin_aries_app/UserInfo' },
              { text: 'Messages', icon: <MessageIcon />, path: '/admin_aries_app/Msg' },
              { text: 'Help', icon: <HelpIcon />, onClick: handleHelp },
            ].map((item, index) => (
              <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 + index * 200 }} key={item.text}>
                <StyledListItem
                  button
                  onClick={item.onClick || (() => handleNavigation(item.path))}
                >
                  <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </StyledListItem>
              </Grow>

            ))}
          </List>
          <Fade in={true} style={{ transitionDelay: '800ms' }}>
            <Box sx={{ p: 2 }}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                startIcon={<ExitToAppIcon />}
                onClick={handleLogout}
                sx={{
                  textTransform: 'none',
                  fontWeight: 500,
                  backgroundColor: '#ff5309',
                  '&:hover': {
                    backgroundColor: '#d64500',
                  },
                }}
              >
                Logout
              </Button>
            </Box>
          </Fade>
        </StyledDrawer>
      </EnhancedDrawer>

      <Container
        maxWidth="lg"
        sx={{
          mt: { xs: 1, sm: 2 }, // Reduced top margin on mobile
          px: { xs: 1, sm: 2, md: 3 },
          py: { xs: 1, sm: 3 }, // Reduced padding on mobile
        }}
      >
        <EnhancedImageSlider>
          {images.map((image, index) => (
            <Box
              key={index}
              component="img"
              src={image}
              alt={`Slider image ${index + 1}`}
              sx={{
                opacity: index === currentImage ? 1 : 0,
              }}
            />
          ))}
        </EnhancedImageSlider>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          {menuItems.map((item, index) => (
            (item.showIf === undefined || item.showIf) && (
              <Grow
                in={true}
                key={index}
                style={{ transformOrigin: '0 0 0' }}
                {...{ timeout: 500 + index * 100 }}
              >
                <Grid item xs={4} sm={3} md={2}>
                  <EnhancedMenuItemCard
                    onClick={item.onClick}
                    sx={item.customSize ? {
                      '& .icon-wrapper': {
                        height: item.customSize.height
                      }
                    } : {}}
                  >
                    <EnhancedIconWrapper className="icon-wrapper">
                      <IconContent
                        customWidth={item.customSize?.width}
                        customHeight={item.customSize?.height}
                      >
                        {item.icon}
                      </IconContent>
                    </EnhancedIconWrapper>
                    <Typography
                      variant="subtitle2"
                      align="center"
                      className="menu-item-label"
                      sx={{
                        fontWeight: 500,
                        fontSize: { xs: '0.75rem', sm: '0.875rem' },
                        mt: 1
                      }}
                    >
                      {item.label}
                    </Typography>
                  </EnhancedMenuItemCard>
                </Grid>
              </Grow>
            )
          ))}
        </Grid>
      </Container>
      {/* Holiday Dialog */}
      < Dialog open={openHolidayDialog} onClose={handleCloseHolidayDialog} >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          Are you sure you want to proceed with the holiday action?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHolidayDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleHolidayView} color="primary" autoFocus>
            View
          </Button>
          <Button onClick={handleHolidayDownload} color="primary" autoFocus>
            Download
          </Button>
        </DialogActions>
      </Dialog >

      {/* Tel Directory Dialog */}
      < Dialog open={openTelDialog} onClose={handleCloseTelDialog} >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          Are you sure you want to proceed with the Telephone action?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTelDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleTelView} color="primary" autoFocus>
            View
          </Button>
          <Button onClick={handleTelDownload} color="primary" autoFocus>
            Download
          </Button>
        </DialogActions>
      </Dialog >
    </Box >
  );
};

export default Home;